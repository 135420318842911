import * as React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

class Footer extends React.Component {

    state = {
        email: "",
        processed: false
    };

    submitNewsletter = (e) => {
        e.preventDefault();
        axios.post("https://api.speedministry.com/v1/mailchimp/signup", {
            email: this.state.email
        })
        .then(() => {
            this.setState({
                processed: true 
            })
        })
        .catch(error => {
            alert('There was an error adding you to our mailing list, please try again with a different email address.')
        })
    };

    render() {
        return (
            <footer>
                <div className="container">
                    <div>
                        <div>
                            <div>
                                <h2>Speed Ministry</h2>
                                <NavLink to="/">Home</NavLink>
                                <NavLink to="/dyno-lookup">Dyno Lookup</NavLink>
                                <NavLink to="/protocol">Protocol Lookup</NavLink>
                                <NavLink to="/projects">Project Database</NavLink>
                                <NavLink to="/credits">Purchase EVC Credits</NavLink>
                            </div>
                            <div>
                                <h2>Mobile Apps</h2>
                                <div className="social">
                                    <a href="https://apps.apple.com/ca/app/speed-ministry/id1482755444" target="_blank" rel="noopener">
                                        <img src={require("../../images/icons/apple.svg")} alt="Apple Icon" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.speedministryapp" target="_blank" rel="noopener">
                                        <img src={require("../../images/icons/google.svg")} alt="Google Icon" />
                                    </a>
                                </div>
                            </div>
                            <div>
                                <h2>Social Media</h2>
                                <div className="social">
                                    <a href="https://www.facebook.com/speedministryofficial/" target="_blank" rel="noopener">
                                        <img src={require("../../images/social/facebook.svg")} alt="Facebook Logo" />
                                    </a>
                                    <a href="https://www.instagram.com/official_speedministry/" target="_blank" rel="noopener">
                                        <img src={require("../../images/social/instagram.svg")} alt="Instagram Logo" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2>Legals</h2>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                                <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink>
                            </div>
                            <div>
                                <h2>Registered Trademark Number</h2>
                                <p>UK00003338887</p>
                            </div>

                            <div>
                                <h2>VAT Number</h2>
                                <p>GB 005 3635 09</p>
                            </div>

                            <div>
                                <h2>Company Number</h2>
                                <p>132742C</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="newsletter" className="newsletter">
                            <h2>Sign up to our newsletter</h2>
                            {this.state.processed === false ? 
                                <>
                                    <p>Receive the latest news and updates from the Speed Ministry&trade; team sent direct to your inbox.</p>
                                    <form className="newsletter">
                                        <input type="email" placeholder="Email Address..." value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                        <input type="submit" name="newsletter" value="Subscribe" onClick={this.submitNewsletter} />
                                    </form>
                                </>
                            :
                                <p>Thanks! You've been added to our mailing list.</p>
                            }
                        </div>
                        <div>
                            <h2>Explore our other products</h2>
                            <div className="otherProjects">
                                <a target="_blank" href="https://www.speedweaver.co.uk">
                                    <img src={require("../../images/logos/sw.svg")} alt="SpeedweaverLogo.svg"/>
                                </a>

                                <a target="_blank" href="https://precisioncode.works">
                                    <img src={require("../../images/logos/pcw.svg")} alt="PCWLogo.svg"/>
                                </a>
                            </div>
                        </div>
                        <p>&copy; 2021 Aerotyne Holdings Limited t/a Speed Ministry. All rights reserved. | Official WinOLS Reseller</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
