import * as React from 'react';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import Header from './components/Header.js';
import CTA from './components/CTA.js';

class Project extends React.Component {
    state = {
        project: {
            ecuMake: {
                name: ""
            },
            ecuProtocol: {
                name: ""
            },
            ecuProductionNo: "",
            make: {
                name: ""
            },
            model: {
                name: ""
            },
            powertrain: {
                name: ""
            },
            type: {
                name: ""
            }
        }
    }

    componentDidMount(){
        // Gets makes
        axios.get('https://api.speedministry.com/v1/projects/id/'+this.props.match.params.project).then((res) => {
            console.log(res.data)
            this.setState({
                project: res.data
            })
        })
    }

    render() {

        const project = this.state.project;

        return (
            <>
                <Helmet>
                    <title>{`${project.ecuMake.name} ${project.ecuProtocol.name} ${project.ecuProductionNo} Tuning File - Speed Ministry`}</title>
                    <meta name="description" content={`${project.projectType} ECU tuning file for ${project.make.name} ${project.model.name} ${project.powertrain.name}`} />
                </Helmet>
                <Header
                    large = {false}
                    h1 = {`${project.ecuMake.name} ${project.ecuProtocol.name} ${project.ecuProductionNo} `}
                    p = {`${project.projectType} ECU tuning file for ${project.make.name} ${project.model.name} ${project.powertrain.name}`}
                />

                <section className="half nopad">
                    <div className="container project">

                        <h3>Project Details</h3>

                        {project.projectType &&
                            <div>
                                <p>Type</p>
                                <p>{project.projectType}</p>
                            </div>
                        }
                        {project.details &&
                            <div>
                                <p>Details</p>
                                <p>{project.details}</p>
                            </div>
                        }
                        {project.maps &&
                            <div>
                                <p>Maps</p>
                                <p>{project.maps}</p>
                            </div>
                        }
                        {project.versions &&
                            <div>
                                <p>Versions</p>
                                <p>{project.versions}</p>
                            </div>
                        }
                        {project.credits &&
                            <div>
                                <p>Cost</p>
                                <p>{project.credits} EVC Credits</p>
                            </div>
                        }

                        <h3>Vehicle Details</h3>

                        {project.type &&
                            <div>
                                <p>Type</p>
                                <p>{project.type.name}</p>
                            </div>
                        }
                        {project.make &&
                            <div>
                                <p>Manufacturer</p>
                                <p>{project.make.name}</p>
                            </div>
                        }
                        {project.model &&
                            <div>
                                <p>Model</p>
                                <p>{project.model.name}</p>
                            </div>
                        }
                        {project.powertrain &&
                            <div>
                                <p>Powertrain</p>
                                <p>{project.powertrain.name}</p>
                            </div>
                        }
                        {project.output &&
                            <div>
                                <p>Vehicle Output</p>
                                <p>{project.output}</p>
                            </div>
                        }

                        <h3>ECU Details</h3>

                        {project.ecuMake &&
                            <div>
                                <p>ECU Manufacturer</p>
                                <p>{project.ecuMake.name}</p>
                            </div>
                        }
                        {project.ecuProtocol &&
                            <div>
                                <p>Protocol</p>
                                <p>{project.ecuProtocol.name}</p>
                            </div>
                        }
                        {project.ecuProductionNo &&
                            <div>
                                <p>Production No.</p>
                                <p>{project.ecuProductionNo}</p>
                            </div>
                        }
                        {project.ecuVersionNo &&
                            <div>
                                <p>Version No.</p>
                                <p>{project.ecuVersionNo}</p>
                            </div>
                        }

                        <h3>Software Details</h3>

                        {project.software &&
                            <div>
                                <p>Software</p>
                                <p>{project.software}</p>
                            </div>
                        }
                        {project.softwareVersion &&
                            <div>
                                <p>Version</p>
                                <p>{project.softwareVersion}</p>
                            </div>
                        }
                        {project.eightBitSum &&
                            <div>
                                <p>8 Bit Sum</p>
                                <p>{project.eightBitSum}</p>
                            </div>
                        }

                    </div>
                </section>
                <CTA 
                    h3={`Download this ${project.ecuMake.name} ${project.ecuProtocol.name} ${project.projectType} for just ${project.credits} EVC Credits.`}
                    buttonLabel = "Purchase EVC Credits"
                    buttonTo = "/credits"
                />
            </>
        );
    }
}

export default Project;
