import * as React from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header.js';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Privacy Policy - Speed Ministry</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = "Privacy statement speedministry.com"
                    p = ""
                />
                <section className="policy notop">
                    <div className="container">
                        <div>
                            <p>This is the privacy statement of speedministy.com. We are located at First Floor, Island House, Isle of Man Business Park, Douglas, Isle of Man, IM22QZ is registered at the Company under number 132742C. This statement is to inform you about which personal data is processed by us and for what purpose this is done. The statement will also explain more about your rights with regard to the protection of your personal data.</p>
                            <p>What is the general privacy policy of speedministry.com</p>
                            <ul>
                                <li>Respect for privacy;</li>
                                <li>Careful &amp; secure data processing;</li>
                                <li>The data will not be shared with third parties;</li>
                                <li>We work with professional partners;</li>
                                <li>We respect the privacy rules and legislation;</li>
                            </ul>
                            <p>We respect the privacy of all visitors to our website and will treat your personal data with due care. We will not sell, trade, or share your data with parties who are not in any way related to our company and services. Above all, we comply with the applicable IOM law and regulations with regard to the protection of data as established in the IOM data protection law.</p>
                            <p>SpeedMinistry OLSx conducts its business activities under the name speedministry.com, hereafter to be called speedministry, it manages this website and it has established this privacy policy to inform you about how we collect, process, and exchange data for this website. If you have any questions regarding this privacy policy, then please contact us by email. We believe that the protection of the privacy of the visitors to our website is vital for your business activities. This is why the personal data of our visitors is treated, processed, and secured with the utmost care. We comply with the requirements as established in the Personal Data Protection Act at all times. The responsible agent for the data processing is speedministry. In accordance with the Personal Data Protection Act, the processing was registered with the IOM supervisory body, the IOM Data Protection Authority in The Hague.</p>
                        </div>

                        <div>
                            <h2>Data Collection</h2>
                            <p>We collect and store the data which you disclose to us via this website or by phone. This data will be used for our services such as the delivery of information you request from us, to inform our suppliers about the information requested by you and/or to deliver ordered products or services, and to provide customer service.</p>
                            <p>This information can also be used to approach you about purchases, special offers and new products or functionalities of our website. Should you not wish to receive information about new products and services, you can choose to opt-out of the marketing cookies or you can have your personal data blocked. You can also retract your prior given consent for the use your email address. In both cases, you can do this by sending a request to this effect by email to info@speedministry.com.</p>
                            <p>We can collect store other data automatically when you visit this website. Each time you visit this website, we can, for example, store your IP address, browser information, and the domain name of the reference site. We also collect information about visitor patterns and the use of the website. This information will be used to evaluate and improve our website and to offer our clients a satisfactory shopping experience.</p>
                        </div>

                        <div>
                            <h2>Which personal data is processed?</h2>
                            <p>In order to execute our services properly and, therefore, provide you with better services, we collect your personal data at the time you use our services or at the time you contact us by filling in the contact form, when you send us an email, or when you request a quotation. We also collect your data when you register on our website. We only process personal data which you have disclosed to us yourself or when personal data is obtained by us through the cookies we place or which can be placed.</p>
                            <p>Below you will find an overview of the personal data we process in any case:</p>
                            <ul>
                                <li>Name of contact person;</li>
                                <li>Email address of contact person;</li>
                                <li>Phone number if this is entered;</li>
                                <li>Company name and address;</li>
                                <li>Company information;</li>
                                <li>VAT &amp; Chamber of Commerce numbers;</li>
                                <li>Other information you have submitted to us of your own accord.</li>
                            </ul>
                        </div>
                        <div>
                            <h2>For which purposes do we collect data and on which grounds?</h2>
                            <p>speedministry processes your personal data for the following purposes:</p>
                            <ul>
                                <li>Sending our newsletter;</li>
                                <li>The correct execution of our service;</li>
                                <li>Sending quotations;</li>
                                <li>To contact you for the purpose of our service;</li>
                                <li>To inform you about changes with respect to our services and products;</li>
                            </ul>
                            <p>We process your personal data based on one of the below-mentioned grounds:</p>
                            <ul>
                                <li>When it is necessary for the execution of our services;</li>
                                <li>When we have obtained your consent to process this personal data.</li>
                            </ul>
                        </div>

                        <div>
                            <h2>How long will your data be stored?</h2>
                            <p>We will not store your personal data any longer than strictly necessary in order for us to supply you with our services.</p>
                        </div>

                        <div>
                            <h2>Personal details</h2>
                            <p>We will not store the details of your visit any longer than strictly necessary. This means that we will only use your personal data to contact you about a request for a quotation or when you have completed the contact form. We will delete the personal data after the contact has taken place unless you have become a client.</p>
                        </div>
                        
                        <div>
                            <h2>Web forms</h2>
                            <p>The personal data which were entered onto our web forms will be automatically deleted from the database of our website after three months.</p>
                        </div>

                        <div>
                            <h2>Invoices</h2>
                            <p>Invoices will be kept for the duration of 10 years which is in compliance with the legal obligation established by the Isle of Man Tax Authorities.</p>
                        </div>

                        <div>
                            <h2>Exchange personal data</h2>
                            <p>We will not share, sell, or rent your personal which you have disclosed to us via this website or by phone to third parties unless you have given your consent to do so as described in this privacy statement. We will disclose your personal data to the suppliers of our product in order to provide you with our services. We use third parties to provide services which are related to the management of our website.</p>
                            <p>We disclose personal data to these third parties under the provision that these parties are only authorized to use this data in connection with the services they provide, and we take the necessary steps to make sure that these third parties take the appropriate measures to comply with all applicable laws regarding data protection. We can exchange non-personal information with our marketing partners, advertisers, and other third parties.</p>
                            <p>Examples of such non-personal information are, among other things, the number of users that visited this website during a certain period of time. This information is generally expressed in numbers.</p>
                            <p>speedministry uses the disclosed information mainly to:</p>
                            <ul>
                                <li>Inform clients about activities related to speedministry;</li>
                                <li>For service activities. These include: offering tips and advice about the use of hardware, contacting users & clients;</li>
                                <li>To inform users, by means of a digital newsletter, about news, tips, and services of speedministry, provided that you have given your consent.</li>
                            </ul>
                        </div>

                        <div>
                            <h2>Deletion of your account</h2>
                            <p>By unsubscribing or opting-out via email, your personal data will be completely deleted from the speedministry database.</p>
                        </div>

                        <div>
                            <h2>Links to or from another website</h2>
                            <p>This website may contain links to websites of third parties. You should be aware that the guidelines described in this privacy statement does not apply to information which is collected via other websites. When you leave this website, we advise you to read the privacy statements of every website you visit. When have accessed this website via a link from any of our advertising or marketing partners, the pages can be themed to that advertising or marketing partner. Nevertheless, the data you disclose on these pages will be collected by us and this privacy statement also applies to the process of that data.</p>
                        </div>

                        <div>
                            <h2>Changes with regard to our privacy policy</h2>
                            <p>If we implement or make material changes in this privacy policy, we will place a notification on this website in order to inform the users of these changes. We advise you to check this website regularly on possible changes in our privacy policy.</p>
                        </div>

                        <div>
                            <h2>How do we secure your personal data?</h2>
                            <p>speedministry secures your data to make sure that it will not get lost or fall into the wrong hands. For this purpose, speedministry has taken the following measures:</p>
                            <ul>
                                <li>The securing of our website with an SSL certificate. This security measure ensures that the personal data you disclose to us through a quotation request or contact form is encrypted when sent;</li>
                                <li>The protection and securing of our computer with a password, whereby we maintain a strict password policy;</li>
                                <li>Your personal data can only be accessed by authorized personnel/third parties;</li>
                                <li>The conclusion of agreements with third parties which are commissioned by us to process your personal data.</li>
                            </ul>
                        </div>

                        <div>
                            <h2>What are your rights as a consumer?</h2>
                            <p>Every consumer is entitled to see its personal data, to edit it, or to delete it. In addition, you, as a consumer, have the right to withdraw your consent for the processing of your data, or to object to the processing of your personal data by us.</p>
                            <p>If you want to object to the processing of your personal data, you can contact us via our contact page or send an email to info@speedministry.com.</p>
                            <p>If you have any complaints about the way we process the personal data, we urge you to inform us of this as soon as possible. We will take your complaint very seriously and, in consultation with you, will do everything in our power to reach a satisfactory conclusion.</p>
                            <p>In addition, we would like to point out that you can always contact the national supervisory body, the IOM Data Protection Authority when you disagree with our solution. Here you can file your complaint.</p>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Privacy;
