import * as React from 'react';
import { Helmet } from 'react-helmet';

import Header from './components/Header.js';

class Terms extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Terms and Conditions - Speed Ministry</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = "Terms and Conditions"
                    p = ""
                />
                <section>
                    <div className="container">
                        <div className="termsList">
                            <ol>
                                <li>Definitions
                                <ol>
                                    <li>Reflection period: the period during which the client has the right to use his right of withdrawal;</li>
                                    <li>Client: the natural person not acting in a professional capacity or on behalf of a company and who enters into a distance agreement with the Company;</li>
                                    <li>Day: calendar day;</li>
                                    <li>Extended transaction: a distance agreement related to a series of products and/or services of which the delivery and/or purchasing obligation is spread over a period of time;</li>
                                    <li>Durable data carrier: every means that offers the Client or the Company the possibility to store information, related or directed to the Client or the Company, in such a way that it is available for future reference and unchanged reproduction of the stored information;</li>
                                    <li>Right of Withdrawal: the right that the Client has to terminate the distance agreement within the reflection period;</li>
                                    <li>Standard Form: the standard form provided by the Company to the Client when he wishes to act on his right of withdrawal;</li>
                                    <li>Company: the natural or legal person that offers products and/or services to clients through distance sales;</li>
                                    <li>Distance agreement: an agreement that has been concluded entirely and solely through the use of one or more types of techniques for distance communication, and which includes the Company's organized system for distance sales of products and/or services up to and including the conclusion of the agreement;</li>
                                    <li>Techniques for distance communication: means that can be used for the conclusion of an agreement without the need for the Client and Company being in the same location at the same time;</li>
                                    <li>General Terms and Conditions: these general terms and conditions as stated by the Company.</li>
                                </ol>
                                </li>

                                <li>Company identity
                                <p>speedministry.com, registered office in Isle of Man, with the office address :<br/>
                                First Floor, Island House, Isle of Man Business Park, Douglas, Isle of Man, IM22QZ<br/>
                                Email address: info@speedministry.com<br/>
                                Company registration number: 132742C<br/>
                                Registered trademark: UK00003338887<br/>
                                VAT-identification number: GB 005 3635 09</p>
                                </li>

                                <li>Applicability
                                <ol>
                                    <li>These general terms and conditions are applicable to every offer done by the Company and to every distance agreement and orders between the Company and the Client.</li>
                                    <li>Before conclusion of the distance agreement,the Client will be provided with a copy of these general terms and conditions. If this is physically not possible, these general terms and conditions can be sent to the Client at the Client's request and without costs.</li>
                                    <li>If the distance agreement is concluded by electronic means, the Company will notify the Client that these general terms and conditions are available on the Company's website. At the Client's request, the Company will forward these general terms and conditions by electronic means and without costs.</li>
                                    <li>In the event of additional product or service specific conditions, the second and third paragraph are applicable and the Client has the right to apply the most favorable term or condition if the specific conditions are contradictory to the general terms and conditions.</li>
                                    <li>Should of one or more terms or conditions in these general terms and conditions become wholly or partially null and void at any moment, the agreement and these general terms and conditions remain applicable and relevant term or condition will be replaced by a new term or condition, that will follow the previous one as closely as possible, without delay and with mutual consent.</li>
                                    <li>Situations that are not regulated by these general terms and conditions will be judged in the spirit of these general terms and conditions.</li>
                                    <li>Ambiguities about the explanation or content of one or more terms or conditions will be explained in the spirit of these general terms and conditions.</li>
                                </ol>
                                </li>

                                <li>The offer
                                <ol>
                                    <li>If an offer has a limited period of validity or has been made on specific conditions, then this will be explicitly mentioned in the offer.</li>
                                    <li>The offer is non-binding. The Company retains the right to change and adapt the offer.</li>
                                    <li>The offer contains a complete and accurate description of the offered products and/or services. The description is sufficiently detailed to obtain a sound assessment of the offer by the Client. When the Company uses pictures, then these are a true projection of the offered products and/or services. Obvious errors or obvious mistakes in the offer are non-binding for the Company.</li>
                                    <li>All pictures and specifications and further data included in the offer are an indication and will not give cause to payment of damages or termination of the agreement.</li>
                                    <li>Pictures of the products are a true projection of the offered products. The Company cannot guarantee that the colors as shown in the pictures are an exact match to the actual colors of the products.</li>
                                    <li>Every offer contains such information that the Client is fully aware of this rights and obligations related to the acceptance of the offer. This applies in particular to:</li>
                                    <ol>
                                        <li>the price, including taxes</li>
                                        <li>any shipment costs;</li>
                                        <li>the manner in which the agreement will be concluded and the relevant actions necessary;</li>
                                        <li>the right of withdrawal, when applicable;</li>
                                        <li>the payment methods, delivery and execution of the agreement;</li>
                                        <li>the period of acceptance of the offer, or the term within which the Company guarantees the offered price;</li>
                                        <li>the rates for distant communication methods in the event of these rates being calcliated on a different basis than the regular base rate for the used method of communication;</li>
                                        <li>the Company will inform the Client if and how the agreement will be put on file after conclusion and how the Client is able to access this file;</li>
                                        <li>the manner in which the Client is able to check and, when necessary, adjust the information provided by him in relation to the agreement, prior to the conclusion of the agreement;</li>
                                        <li>any other languages in which the agreement can be drawn up, in addition to the english language;</li>
                                        <li>the codes of conduct the Company adheres to and the electronic manner in which the Client can refer to these codes of conduct, and</li>
                                        <li>the minimum duration of the distance agreement in the event of an extended agreement.</li>
                                    </ol>
                                </ol>
                                </li>

                                <li>The agreement
                                <ol>
                                    <li>Under the condition of paragraph 4, the agreement is concluded at the time of the Client's acceptance of the offer and when the relevant terms and conditions have been met.</li>
                                    <li>If the Client has accepted the offer in by electronic means, the Company will confirm receipt of the acceptance without delay by electronic means. As long as this acceptance of the offer has not been confirmed by the Company, the Client has the right to cancel or terminate the agreement.</li>
                                    <li>In the event of conclusion of the agreement by electronic means, the Company will take appropriate technical and organizational measures to ensure the security of the electronic data transfers and ensure a secure web environment. If the Client has the means for the electronic payments, the Company will take appropriate security measures.</li>
                                    <li>The Company remains the right - within legal boundaries - to check whether the Client is able to meet his financial obligations, as well as all those facts and factors that are essential to a responsible conclusion of the distance agreement. Should this investigation give cause to cancel the conclusion of the agreement, the Company retains the right to reject an order or request by stating reasons, or to attach special conditions to the agreement.</li>
                                    <li>The Company will include the following information on delivery of the product or service to the Client, either in writing or in such a way that the Client can easily access this information and store it on a durable data carrier:
                                        <ol>
                                            <li>the visiting address of the company to which the Client can refer to in the event of defects or complaints;</li>
                                            <li>the conditions applying to the Client's right of withdrawal, and/or a clear description regarding the exclusions of the right of withdrawal;</li>
                                            <li>the relevant information about warranties and services after the purchase;</li>
                                            <li>the information stated in Article 4, paragraph 3 of these general terms and conditions, unless the Company has already provided the Client with this information prior to the conclusion of the agreement.</li>
                                            <li>the requirements with regard to termination of the agreement when the duration of the agreement is more than one year or indefinite.</li>
                                        </ol>
                                    </li>
                                    <li>If the agreement involves an extended transaction, the condition in the previous paragraph is only applicable to the first delivery.</li>
                                    <li>Each agreement is concluded subject to sufficient availability of the relevant products.</li>
                                </ol>
                                </li>

                                <li>Right of Withdrawal
                                <ol>
                                    <li>With regard to delivery of products:
                                        <ol>
                                            <li>When purchasing products, the Client has the right to terminate the agreement within 14 days without stating reasons. This reflection period starts on the day of receipt of the product by the Client or by a representative appointed by the Client after notification to the Company.</li>
                                            <li>During this reflection period the Client will take appropriate care of the product and the packaging. The Client will only unwrap or use the product to the extent of which he is able to decide whether he wants to keep the product. Should the Client use his right of withdrawal, he will return the product and all the accessories and - when reasonably possible - in its original state and packaging to the Company in compliance with the reasonable and clear instructions provided by the Company.</li>
                                            <li>Should the Client wants to use his right of withdrawal, he will notify the Company of this within 14 days of receipt of the product. The Client will use the standard form for this purpose. After giving the Company notification of using the right of withdrawal, the Client will return the product within 14 days. The Client will provide the Company with, for instance, a proof of dispatch as evidence of the timely return of the product.</li>
                                            <li>If the Client has not given any notification of the use of the right of withdrawal according to the terms stated in paragraph 2 and 3 and has not returned the product to the Company, the purchase is binding.</li>
                                        </ol>
                                    </li>
                                    <li>With regard to delivery of services:
                                        <ol>
                                            <li>On delivery of services the Client has the right to terminate the agreement without stating reasons during a period of at least 14 days starting on the day of conclusion of the agreement.</li>
                                            <li>In order to use his right of withdrawal, the Client will follow the instructions provided by the Company when the offer was made and/or no later on delivery of the services.</li>
                                        </ol>
                                    </li>
                                </ol>
                                </li>


                                <li>Costs in the event of withdrawal
                                <ol>
                                    <li>Should the Client use his right of withdrawal, the costs incurred on the Client will not exceed the costs of the return shipment.</li>
                                    <li>In the event of the Client having fulfilled any payment, the Company will refund the payment as soon as possible and no later than 14 days after the withdrawal. Refunds will take place under the condition that the product has been delivered at the address of speedministry or on giving conclusive evidence that the product has been shipped back in its entirety.</li>
                                </ol>
                                </li>

                                <li>Exclusions to the Right of Withdrawal
                                <ol>
                                    <li>The Company remains the right to exclude the right of withdrawal for products as stated in paragraph 2 and 3. The exclusion of the right of withdrawal is only valid when the Company has stated this clearly in the offer, or at least in a timely period prior to the conclusion of the agreement.</li>
                                    <li>With regard to products, exclusion of the right of withdrawal can only be made for:
                                        <ol>
                                            <li>products that have been manufactured by the Company according to specifications by the Client;</li>
                                            <li>audio and video recordings and computer software of which the seal has been broken by the Client or to which changes have been made by the Client.</li>
                                        </ol>
                                    </li>
                                    <li>With regard to services, exclusion of the right of withdrawal can only be made for:
                                        <ol>
                                            <li>services that have started before the end of the reflection time with the explicit consent of the Client</li>
                                        </ol>
                                    </li>
                                </ol>
                                </li>

                                <li>The price
                                <ol>
                                    <li>During the period of validity stated in the offer, the prices of products and/or services will not be raised, with the exemption of price changes resulting from:
                                        <ol>
                                            <li>changes in the VAT rates;</li>
                                            <li>changes resulting from legislative requirements and regulations;</li>
                                        </ol>
                                    </li>
                                    <li>The prices for products and/or services as stated in the offer are inclusive of VAT. *</li>
                                    <li>All prices are subject to printing and typographical errors. The Company does not accept liability for the consequences of printing and typographical errors. The Company is not obliged to deliver the product for the wrong price resulting from printing and typographical errors.</li>
                                </ol>
                                </li>

                                <li>Conformity and Warranty
                                <ol>
                                    <li>The Company will ensure that the products and/or services meet the requirements of the agreement, the specifications as stated in the offer, the reasonable requirements of reliability and/or suitability and the legislative requirements and regulations at the date of the conclusion of the agreement. If so agreed, the Company will also ensure that the product is suitable for other use than the one stated in the agreement.</li>
                                    <li>Any warranty given by the Company, the manufacturer or importer does not detract from the legal rights and claims the Client may exercise against the Company based on the agreement.</li>
                                    <li>The Client will notify the Company in writing about any defects or faulty deliveries within 2 weeks after delivery. Return shipment of the products will be done in the original packaging and in new condition.</li>
                                    <li>The warranty period of the Company is the same as the warranty period of the manufacturer. The Company is, however, never responsible for the final suitability of the products with regard to each individual use by the Client, nor for any advice with regard to the use of the application of the products.</li>
                                    <li>The warranty does not apply when:
                                        <ol>
                                            <li>Any repair work and/or any other work on the delivered products has been done by the Client or by third parties;</li>
                                            <li>The delivered products have been exposed to abnormal circumstances or have been treated in any other irresponsible way or in any manner in breach with the directions of use provided by the Company and/or as stated on the packaging;</li>
                                            <li>The defect is wholly or partially the result from (future) legislative regulations with regard to the nature or quality of the materials used.</li>
                                        </ol>
                                    </li>
                                </ol>
                                </li>

                                <li>Delivery and execution
                                <ol>
                                    <li>The Company will take the utmost care and responsibility with regard to the acceptance and execution orders of products and the assessments of requests for services.</li>
                                    <li>The location of delivery is the address that the Client has provided to the Company.</li>
                                    <li>Taking into account the content of paragraph 4 of this Article, the Company will execute accepted orders without delay but not later than 30 days after the conclusion of the agreement, unless the Client has accepted a longer period of delivery. In the event of the delivery being delayed, or if the agreement can only be partially executed or not at all, the Client will be notified of this not later than 30 days after placing the order. If this is the case, the Client has the right to terminate the agreement without costs. The Client is not entitled to any damages.</li>
                                    <li>All delivery dates are indicative. The Client does not remain any rights with regard to delivery periods stated in the agreement. If a delivery date is exceeded, the Client has no rights to any damages.</li>
                                    <li>In the event of termination of the agreement in accordance with paragraph 3 of this Article, the Company will refund the amount paid by the Client as soon as possible, and not later than 14 days after the termination.</li>
                                    <li>If the Company is unable to deliver the ordered product, the Company will do its utmost to find a replacement product. The Company will notify the Client about sending a replacement product not later than at the moment of delivery and in a clear and understandable manner. Replacement products are never subject to exclusion of the right of withdrawal. The costs of the return shipment are at the expense of the Company.</li>
                                    <li>Any risk with regard to damages or loss of the product shall remain with the Company until the moment of delivery at the address of the Client or at the address of a representative appointed by the Client after notification to the Company, unless expressly otherwise agreed.</li>
                                </ol>
                                </li>

                                <li>Extended transactions: period, termination and extension
                                <ol>
                                    <li>Termination
                                        <ol>
                                            <li>The Client has the right to terminate an indefinite agreement which includes a purchase of products and/or services on a regular basis at any time, with due observance of a contractual termination terms and of a notice period of not more than one month.</li>
                                            <li>The Client has the right to terminate a definite agreement which includes a purchase of products and/or services on a regular basis at any time at the time of the end of the agreement period, with due observance of a contractual termination terms and of a notice period of not more than one month.</li>
                                            <li>The Client has the right to terminate the agreements as mentioned above:
                                                <ol>
                                                    <li>at all times without restriction to a certain date or period;</li>
                                                    <li>in the same manner as to how the agreement has been concluded;</li>
                                                    <li>observe the same notice period as the Company has stipulated for itself.</li>
                                                </ol>
                                            </li>
                                            <li>Extension
                                                <ol>
                                                    <li>Any definite agreement that includes the regular purchase of products and/or services cannot be extended or renewed without mutual consent.</li>
                                                </ol>
                                            </li>
                                            <li>Duration
                                                <ol>
                                                    <li>If the duration of an agreement is longer than one year, the Client has the right to terminate this agreement with due observance of a notice period of not more than one month, unless the consequences would be contrary to the principles of reasonableness and fairness.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                </li>

                                <li>Payment
                                    <ol>
                                        <li>Unless explicitly agreed differently, the Client will ensure payment of any amount due within 7 working days after the start of the reflection period as stated in Article 6, paragraph 1. If the agreement includes the delivery of a service, this period will start after the Client has received the acceptance of the agreement.</li>
                                        <li>The Client has the obligation to notify the Company without delay of any inaccuracies in the payment details.</li>
                                        <li>In the event of default or non-payment on the part of the Client, the Company, subject to legal restrictions, is entitled to charge any predetermined reasonable costs incurred to the Client.</li>
                                    </ol>
                                </li>

                                <li>Complaints
                                <ol>
                                    <li>The Company has a sufficiently published complaints procedure and shall deal with any complaint in accordance with this procedure.</li>
                                    <li>In the event of any complaints about the execution of the agreement, the Client will notify the Company of the complaint within 7 days after the complaint arises, clearly and fully detailed and in writing.</li>
                                    <li>The Company will act on any complaint within 14 days from the date of receipt of the complaint. If it becomes clear that the handling of the complaint will take a longer time to solve, the Company will notify the Client of this within the 14 days period and provide the Client with an indication of when a more extensive answer can be expected.</li>
                                    <li>If the complaint cannot be solved to mutual satisfaction, a dispute arises to which a dispute settlement may apply.</li>
                                    <li>In the event of any complaint, the Client will first turn to speedminstry.com If a complaint cannot be solved to mutual satisfaction, the Client will turn to xx, which offers mediation without costs. If this step does not offer a solution to the complaint , the Client has the option to take his complaint to the xx . The Company and the Client will agree that any ruling by xx is binding for the Company as well as the Client, Taking a complaint to the xx is not without costs and they will be at the expense of the Client. It is also possible to report complaints to the <a href="http://ec.europa.eu/odr">European Online Dispute Resolution platform</a></li>
                                    <li>A complaint shall not suspend the obligations of the Company and/or the Client, unless the Company states otherwise in writing.</li>
                                    <li>If the Company has found the complaint well-founded, it will either exchange or repair the returned products without costs.</li>
                                </ol>
                                </li>


                                <li>Disputes
                                <ol>
                                    <li>IOM law is applicable to all agreements between the Company and the Client that fall under these general terms and conditions. This is also the case when the Client has his domicile outside the Netherlands.</li>
                                    <li>The United Nations Convention for the international Sales of goods (CISG) is not applicable.</li>
                                </ol>
                                </li>

                                <li>Additional or deviant conditions
                                <ol>
                                    <li>In addition to these general terms and conditions, any other additional or deviant conditions may not be to the disadvantage of the Client and shall be recorded in writing or in such a way that they can be stored by the Client in an easily accessible way on a durable data carrier.</li>
                                </ol>
                                </li>

                                <li>Credits
                                <ol>
                                    <li>speedministry.com uses a prepaid credit system, these credits are purchased in advance and are credited to the account. The credits remain valid as long as the account is online, so they do not expire after a certain period. When an account with speedministry.com is removed, the credits still remaining in the account will also be canceled. Refund is not possible.</li>
                                </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Terms;
