import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import axios from 'axios';
import ReactGA from "react-ga";
import Analytics from "analytics";
import segmentPlugin from '@analytics/segment';

import Header from './components/Header.js';

const analytics = Analytics({
    app: 'speed-ministry-website',
    plugins: [
        segmentPlugin({
            writeKey: 'F9La60nNaZszkGUc2NuTtJZNdUwx8Z6w'
        })
    ]
});

class Credits extends React.Component {

    state = {
        products: [],
        processing: false,
        fullName: "",
        email: "",
        customer: "",
        validCustomer: false,
        mailchimp: false,
        error: null,
    };

    componentDidMount(){

        if(localStorage.getItem("qrnsczqhqp")){
            this.setState({
                customer: localStorage.getItem("qrnsczqhqp")
            }, () => {
                this.checkAccount(false)
            })
        }

        axios.get("https://api.speedministry.com/v1/store/skus")
        .then((res) => {

            // Sort
            let products = res.data.sort((a, b) => (a.unit_amount > b.unit_amount) ? 1 : -1);

            this.setState({
                selected: products[1].product.id,
                selectedSpan: "prod_G39v7I312yrdEb",
                products: products
            })
        })
    }

    checkAccount = (connect) => {
        if(connect){
            axios.get("https://api.speedministry.com/v1/store/account/"+this.state.connectCustomer)
            .then((res) => {
                this.setState({
                    connectAccountValid: res.data.userExists
                })
            })
        } else {
            axios.get("https://api.speedministry.com/v1/store/account/"+this.state.customer)
            .then((res) => {
                this.setState({
                    accountValid: res.data.userExists
                })
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({processing: true});

        localStorage.setItem("qrnsczqhqp", this.state.customer);

        if(this.state.mailchimp === true){
            axios.post("https://api.speedministry.com/v1/mailchimp/signup", {
                email: this.state.email
            })
        }

        let credits = 0;

        this.state.products.map((product, idx) => {
            if(product.product.id === this.state.selected){
                credits = product.product.name.split(" ")[0];
            }
        });

        axios.post("https://api.speedministry.com/v1/store/order", {
            billing_details_collection: "auto",
            success_url: `https://speedministry.com/credits/success?credits=${credits}&account=${this.state.customer}`,
            cancel_url: `https://speedministry.com/credits/cancel?credits=${credits}&account=${this.state.customer}`,
            coupon_collection: true,
            customer:{
                first_name: this.state.fullName.split(" ")[0],
                last_name: this.state.fullName.split(" ")[1],
                email: this.state.email
            },
            items: [
                {
                    product: this.state.selected
                }
            ],
            custom: {
                stripe: {
                    metadata: {
                        evc: this.state.customer
                    }
                }
            }
        })
        .then((res) => {

            let product = this.state.products.filter((product) => {
                return product.product.id === this.state.selected;
            })[0];

            analytics.identify(this.state.email, {
                firstName: this.state.fullName.split(" ")[0],
                lastName: this.state.fullName.split(" ")[1],
                email: this.state.email
            }).then(() => {
                analytics.track('checkoutInitiated', {
                    item: product.product.name,
                    price: product.unit_amount / 100,
                    checkoutUrl: res.data.permalink
                });
            });

            window.location.href = res.data.permalink;
        })
        .catch(error => {
            alert('There was an error processing your order, please try again.');
            window.location.href = '/credits';
        })
    };

    connectHandleSubmit = (e) => {
        e.preventDefault();
        this.setState({connectProcessing: true});

        localStorage.setItem("qrnsczqhqp", this.state.customer);

        if(this.state.connectMailchimp === true){
            axios.post("https://api.speedministry.com/v1/mailchimp/signup", {
                email: this.state.connectEmail
            })
        }

        axios.post("https://api.speedministry.com/v1/store/account/connect/"+this.state.connectCustomer, {
        })
        .then((res) => {
            alert('Your account has been successfully linked to our reseller database, thank you.');

            ReactGA.initialize("UA-147194496-2");
            ReactGA.event({
                category: 'account',
                action: 'connectedEVC'
            });

            window.location.href = '/credits';
        })
        .catch(error => {
            alert('There was an error processing connecting your account, please try again.');
            window.location.href = '/credits';
        })
    };

    render() {

        const fullNameValid = /(\w.+\s).+/i.test(this.state.fullName);
        const emailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email);
        const submittable = fullNameValid && emailValid && this.state.accountValid;

        const connectFullNameValid = /(\w.+\s).+/i.test(this.state.connectFullName);
        const connectEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.connectEmail);
        const connectSubmittable = connectFullNameValid && connectEmailValid && this.state.connectAccountValid;

        return (
            <>
                <Helmet>
                    <title>Purchase EVC Tuning File Credits - Speed Ministry</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = "Purchase EVC Tuning File Credits"
                    p = "When purchasing credits for the first time, your EVC account will automatically be linked to our reseller database."
                />
                <section className="notop">
                    <div className="container">
                        <div className="multiLineForm">
                            <form onSubmit={this.handleSubmit}>

                                <h3>Select Quantity:</h3>
                                <div className="products">
                                    {this.state.products.map((product, idx) => {
                                        return (
                                            <div key={idx} onClick={() => { this.setState({ selected: product.product.id, selectedSpan: product.product.id })} } className={this.state.selected == product.product.id ? "selected" : null}>
                                                <p>{product.product.name}</p>
                                                <p>&euro; {(product.unit_amount / 100).toFixed(2)}</p>
                                                {idx === 1 ? <span className={this.state.selectedSpan == product.product.id ? "selected label" : "label"}>Most Popular</span> : null}
                                                {idx === 3 ? <span className={this.state.selectedSpan == product.product.id ? "selected label" : "label"}>Best Value</span> : null}
                                            </div>
                                        )
                                    })}
                                </div>
                                
                                <h3>Your Details:</h3>
                                <div className="details">
                                    <input type="text" placeholder="Full Name" className={fullNameValid ? "valid" : "error"} value={this.state.fullName} onChange={(e) => {this.setState({fullName: e.target.value})}}/>
                                    <input type="email" placeholder="Email Address" className={emailValid ? "valid" : "error"} value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
                                    <input type="number" placeholder="EVC Account No." value={this.state.customer} className={this.state.accountValid ? "valid" : "error"} onChange={(e) => {this.setState({customer: e.target.value}, () => {this.checkAccount(false)})}}/>
                                </div>

                                <div className="checkContainer">
                                    <input type="checkbox" className="checkbox" defaultChecked={this.state.mailchimp} onClick={(e) => { this.setState({ mailchimp: !this.state.mailchimp }) }} id="mailchimp"/>
                                    <label htmlFor="mailchimp">Would you like to recieve updates and news via email?</label>
                                </div>

                                {this.state.processing ?
                                    <button className="btn-disabled" disabled="true">Processing Payment...</button>
                                : 
                                    <button type="submit" className={submittable ? "btn-primary" : "btn-disabled"} disabled={!submittable}>Continue to Payment</button>
                                }
                                
                            </form>
                        </div>
                    </div>
                </section>

                <section className="grey half">
                    <div className="container split">
                        <div>
                            <h2>Explore our Reseller Database</h2>
                            <p>If you'd like to see what ECU tuning files we offer before purchasing credits you can explore our entire reseller database from our website. Alternatively, fill out the form below to link your account to our reseller database.</p>
                        </div>
                        <NavLink to="/projects" className="btn-primary">View Reseller Projects</NavLink>
                    </div>
                </section>

                <section className="half">
                    <div className="container multiLineForm">
                        <h2>Connect to our Reseller Database</h2>
                        <p>Enter your EVC account number below to link your account to our reseller database.</p>
                        <form onSubmit={this.connectHandleSubmit}>
                            <div className="details">
                                <input type="text" placeholder="Full Name" className={connectFullNameValid ? "valid" : "error"} value={this.state.connectFullName} onChange={(e) => {this.setState({connectFullName: e.target.value})}}/>
                                <input type="email" placeholder="Email Address" className={connectEmailValid ? "valid" : "error"} value={this.state.connectEmail} onChange={(e) => {this.setState({connectEmail: e.target.value})}}/>
                                <input type="number" placeholder="EVC Account No." value={this.state.connectCustomer} className={this.state.connectAccountValid ? "valid" : "error"} onChange={(e) => {this.setState({connectCustomer: e.target.value}, () => {this.checkAccount(true)})}}/>
                            </div>

                            <div className="checkContainer">
                                <input type="checkbox" className="checkbox" defaultChecked={this.state.connectMailchimp} onClick={(e) => { this.setState({ connectMailchimp: !this.state.connectMailchimp }) }} id="connectMailchimp"/>
                                <label htmlFor="connectMailchimp">Would you like to recieve updates and news via email?</label>
                            </div>

                            {this.state.connectProcessing ?
                                <button className="btn-disabled" disabled="true">Connecting Your Account</button>
                            : 
                                <button type="submit" className={connectSubmittable ? "btn-primary" : "btn-disabled"} disabled={!connectSubmittable}>Connect Account</button>
                            }
                        </form>
                    </div>
                </section>


                <section className="grey half">
                    <div className="container">
                        <div className="halfContent">
                            <div>
                                <h3>Will taxes be included in my invoice?</h3>
                                <p>Taxes will be accounted for and worked out based on location of the user. Tax breakdowns will be included on all invoices via email.</p>
                            </div>
                            <div>
                                <h3>How secure is my payment?</h3>
                                <p>We use Stripe as our payment merchant. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.</p>
                            </div>
                            <div>
                                <h3>How can I top up my EVC credits?</h3>
                                <p>You can download the dedicated app or top up your account with a valid EVC license here on the site.</p>
                            </div>
                            <div>
                                <h3>How do I register for an EVC account?</h3>
                                <p>You can register for your EVC account from their website <a href="https://www.evc.de/en/contact/newcustomer.asp" target="_blank" rel="noopener">here</a>. Once you have your account number, simply place an order for credits on our site to be added to our reseller platform.</p>
                            </div>
                            <div>
                                <h3>What ECUs / Vehicles are supported?</h3>
                                <p>Use the SpeedMinistry App on iOS and Android to browse all of our available projects as well vehicle protocol and dyno data.</p>
                            </div>
                            <div>
                                <h3>There was a problem with my purchase, who should I contact?</h3>
                                <p>Please contact our customer support team via email or our live chat service during our business hours: 8am - 6pm GMT Monday to Friday</p>
                            </div>
                            <div>
                                <h3>Do my EVC credits expire?</h3>
                                <p>No, they are valid as long as EVC supports the reseller platform.</p>
                            </div>
                            <div>
                                <h3>Can I request a project that isn't already in the reseller database?</h3>
                                <p>Yes, via our sister file service site <a href="https://www.precisioncode.works/" target="_blank" rel="noopener">PrecisionCodeWorks</a>.</p>
                            </div>
                            <div>
                                <h3>How much do ECU tuning files cost?</h3>
                                <p>Prices start from just 50 euros.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withRouter(Credits);
