import * as React from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios';
import { Helmet } from 'react-helmet';

import Header from './components/Header.js';
import CTA from './components/CTA.js';

class Protocol extends React.Component {

    state = {
        show: 1,
        searchInput: "",
        searchInputModel: "",
        manufacturers: [],
        models: [],
        years: [],
        powertrains: [],
        data: [],
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname != this.props.location.pathname){
            this.checkForParams();
            console.log('Moved to '+ this.props.location.pathname)
        }
    }
    componentDidMount(){
        this.checkForParams();
    }
    
    
    checkForParams = () => {
            
        console.log('check for params just ran')

        if(this.props.match.params.make){
            this.setState({
                manufacturerSlug: this.props.match.params.make
            }, () => {
                if(this.props.match.params.model){
                    this.setState({
                        modelSlug: this.props.match.params.model
                    }, () => {
                        if(this.props.match.params.year){
                            this.setState({
                                year: this.props.match.params.year
                            }, () => {
                                if(this.props.match.params.powertrain){
                                    this.setState({
                                        powertrainId: this.props.match.params.powertrain
                                    }, () => {
                                        this.appliedPowertrain(this.state.powertrainId);
                                    })
                                } else {
                                    this.appliedYear(this.state.year);
                                }
                            })
                        } else {
                            this.appliedModel(this.state.modelSlug);
                        }
                    })
                } else {
                    this.appliedMake(this.state.manufacturerSlug);
                }
            })
        } else {
            this.defaultRequest();
        }
    }

    defaultRequest = () => {
        // Gets makes
        axios.get('https://api.data.octalpowertrain.com/v1/ecu/vehicles/types/car/makes').then((res) => {
            this.setState({
                manufacturers: res.data
            })
        }).catch((err) => {
            this.props.history.push('/error');
            console.log(1)
        })
    }
    
    appliedMake = (manufacturerSlug) => {
        this.setState({
            // Applies make details to state
            manufacturerSlug: manufacturerSlug
        }, () => {
            console.log(2)
            // Gets models
            axios.get('https://api.data.octalpowertrain.com/v1/ecu/vehicles/types/car/makes/'+this.state.manufacturerSlug+'/models').then((res) => {
                if(res.data.length < 1){
                    this.setState({
                        show: 1,
                        manufacturerSlug: ""
                    })
                    this.props.history.push('/protocol/')
                    console.log(13)
                    this.defaultRequest()
                } else{
                    this.setState({
                        show: 2,
                        models: res.data
                    })    
                }
            }).catch((err) => {
                this.setState({
                    show: 1,
                    manufacturerSlug: ""
                })
                this.props.history.push('/protocol/')
                console.log(3)
                this.defaultRequest()
            })
        })
    }
    
    appliedModel = (modelSlug) => {
        // Applies model details to state
        this.setState({
            modelSlug: modelSlug
        }, () => {
            this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + this.state.modelSlug)
            console.log(4)
            // Gets years
            axios.get('https://api.data.octalpowertrain.com/v1/ecu/vehicles/types/car/makes/'+this.state.manufacturerSlug+'/models/'+this.state.modelSlug+'/years').then((res) => {    
            if(res.data.length < 1){
                this.setState({
                    show: 1,
                    manufacturerSlug: "",
                    modelSlug: ""
                })
                this.props.history.push('/protocol')
                console.log(5)
                this.defaultRequest()
            } else{
                this.setState({
                    show: 3,
                    years: res.data
                })    
            }
            }).catch((err) => {
                this.setState({
                    show: 1,
                    manufacturerSlug: "",
                    modelSlug: ""
                })
                this.props.history.push('/protocol')
                console.log(6)
                this.defaultRequest()
            })
        })
    }

    appliedYear = (year) => {
        // Applies model details to state
        this.setState({
            year: year
        }, () => {
            this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + this.state.year)
            console.log(7)
            axios.get('https://api.data.octalpowertrain.com/v1/ecu/vehicles/types/car/makes/'+this.state.manufacturerSlug+'/models/'+this.state.modelSlug+'/years/' + this.state.year + '/powertrains').then((res) => {    
                if(res.data.length < 1){
                    this.setState({
                        show: 1,
                        manufacturerSlug: "",
                        modelSlug: "",
                        year: ""
                    })
                    this.props.history.push('/protocol')
                    console.log(8)
                    this.defaultRequest()
                } else{
                    this.setState({
                        powertrains: res.data,
                        show: 4
                    })    
                }    
            }).catch((err) => {
                this.setState({
                    show: 1,
                    manufacturerSlug: "",
                    modelSlug: "",
                    year: ""
                })
                this.props.history.push('/protocol')
                console.log(9)
                this.defaultRequest()
            })
        })
    }

    appliedPowertrain = (powertrainId) => {
        // Applies model details to state
        this.setState({
            powertrainId: powertrainId,
        }, () => {
            this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + this.state.year + '/' + this.state.powertrainId)
            console.log(10)
            axios.get('https://api.data.octalpowertrain.com/v1/ecu/vehicles/id/' + this.state.powertrainId).then((res) => {
                if(res.data.length < 1){
                    this.setState({
                        show: 1,
                        manufacturerSlug: "",
                        modelSlug: "",
                        year: "",
                        powertrainId: ""
                    })
                    this.props.history.push('/protocol')
                    console.log(11)
                    this.defaultRequest()
                } else{
                    this.setState({
                        data: res.data,
                        show: 5
                    })    
                }    
            }).catch((err) => {
                this.setState({
                    show: 1,
                    manufacturerSlug: "",
                    modelSlug: "",
                    year: "",
                    powertrainId: ""
                })
                this.props.history.push('/protocol')
                console.log(12)
                this.defaultRequest()
            })
        })
    }

    render() {

        let makesArray = []
        let manufacturers = this.state.manufacturers;
        manufacturers.filter((obj) => {
            if(obj.name.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) > -1){
                makesArray.push(obj)
            }
        })

        let modelsArray = []
        let models = this.state.models;
        models.filter((obj) => {
            if(obj.name.toLowerCase().indexOf(this.state.searchInputModel.toLowerCase()) > -1){
                modelsArray.push(obj)
            }
        })

        let title = "Protocol Lookup";
        let sub = "ECU Protcol Details and Vehicle Stats";

        if(this.state.data.make){
            title = `${this.state.data.make.name} ${this.state.data.model.name} ${this.state.data.powertrain}`;
            sub = "ECU Protcol Details and Vehicle Stats";
        }

        const data = this.state.data;

        return (
            <>
                <Helmet>
                    <title>{`${title} Protocol Details - Speed Ministry`}</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = {title}
                    p = {sub}
                />
                {this.state.show === 1 ?
                    <section className="nopad">
                        <div className="container">
                            <h2>Select a Make</h2>
                            <input className="highlight" type="text" placeholder="Search manufacturers..." value={this.state.searchInput} onChange={(e) => { this.setState({ searchInput: e.target.value }) }}/>
                            <div className="dynoList">
                                {makesArray.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/protocol/' + obj.slug); }}>
                                            { obj.logo ?
                                                <img src={"https://fra1.digitaloceanspaces.com/octal-content/vehicle-make-logos/" + obj.logo} alt="" />
                                            : 
                                            <p>{obj.name}</p>
                                            }
                                        </div>
                                    )
                                })}
                            </div>  
                        </div>
                    </section>
                : null}

                {this.state.show === 2 ?
                    <section className="nopad">
                        <div className="container">
                            <h2>Select a Model</h2>
                            <input className="highlight" type="text" placeholder="Search models..." value={this.state.searchInputModel} onChange={(e) => { this.setState({ searchInputModel: e.target.value }) }}/>
                            <div className="dynoList">
                                {modelsArray.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + obj.slug); }}>
                                            <p>{obj.name}{obj.code !== null ? " - "+obj.code : null}{obj.type !== null ? " - "+obj.type : null}</p>
                                        </div>
                                    )
                                })}
                            </div>  
                        </div>
                    </section>
                : null}

                {this.state.show === 3 ?
                    <section className="nopad">
                        <div className="container database">                            
                            <h2>Select a Year</h2>
                            <div className="dynoList">
                                {this.state.years.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + obj); }}>
                                            <p>{obj}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                : null}

                {this.state.show === 4 ?
                    <section className="nopad">
                        <div className="container database">                           
                            <h2>Select a Powertrain</h2>
                            <div className="dynoList">
                                {this.state.powertrains.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/protocol/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + this.state.year + '/'+ obj.id); }}>
                                            <p>{obj.powertrain}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                : null}

                {this.state.show === 5 ?
                    <section className="half nopad">
                        <div className="container database">
                            <div className="project">

                                <h3>Vehicle Details</h3>

                                {data.make &&
                                    <div>
                                        <p>Manufacturer</p>
                                        <p>{data.make.name}</p>
                                    </div>
                                }
                                {data.model &&
                                    <div>
                                        <p>Model</p>
                                        <p>{data.model.name}</p>
                                    </div>
                                }
                                {data.model.code &&
                                    <div>
                                        <p>Code</p>
                                        <p>{data.model.code}</p>
                                    </div>
                                }
                                {data.model.generation &&
                                    <div>
                                        <p>Generation</p>
                                        <p>{data.model.generation}</p>
                                    </div>
                                }
                                {data.powertrain &&
                                    <div>
                                        <p>Powertrain</p>
                                        <p>{data.powertrain}</p>
                                    </div>
                                }
                                {data.year &&
                                    <div>
                                        <p>Year</p>
                                        <p>{data.year}</p>
                                    </div>
                                }
                                {data.fuel &&
                                    <div>
                                        <p>Fuel</p>
                                        <p>{data.fuel}</p>
                                    </div>
                                }

                                <h3>ECU Details</h3>

                                {data.ecus.map((ecu, idx) => {
                                    return (
                                        <div key={idx}>
                                            <p>ECU {idx + 1}</p>
                                            <p>{`${ecu.make.name} ${ecu.protocol.name}`}</p>
                                        </div>
                                    )
                                })}

                                {data.engine &&
                                    <>
                                        <h3>Engine Details</h3>
                                        {data.engine.manufacturer &&
                                            <div>
                                                <p>Manufacturer</p>
                                                <p>{data.engine.manufacturer}</p>
                                            </div>
                                        }
                                        {data.engine.model &&
                                            <div>
                                                <p>Model</p>
                                                <p>{data.engine.model}</p>
                                            </div>
                                        }
                                        {data.engine.code &&
                                            <div>
                                                <p>Code</p>
                                                <p>{data.engine.code}</p>
                                            </div>
                                        }
                                    </>
                                }

                                <h3>Stats</h3>

                                {data.hp &&
                                    <div>
                                        <p>HP</p>
                                        <p>{data.hp}</p>
                                    </div>
                                }
                                {data.nm &&
                                    <div>
                                        <p>NM</p>
                                        <p>{data.nm}</p>
                                    </div>
                                }
                                {data.ps &&
                                    <div>
                                        <p>PS</p>
                                        <p>{data.ps}</p>
                                    </div>
                                }
                                {data.kw &&
                                    <div>
                                        <p>KW</p>
                                        <p>{data.kw}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                : null}
                <CTA 
                    h3={`Add credits to your EVC account for as little as €66 to get started`}
                    p={`Access our database of over thousands of ECU tuning files directly from your EVC WinOLS software, from Original Files, to Stage 1's and Super Mappacks`}
                    buttonLabel = "Purchase EVC Credits"
                    buttonTo = "/credits"
                />
            </>
        )
    }
}

export default withRouter(Protocol);
