import * as React from 'react';
import { NavLink } from 'react-router-dom';

class Header extends React.Component {
    render() {
        return (
            <header className={this.props.large ? "large" : null}>
                <div className="container">
                    <div>
                        { this.props.h1 && <h1>{this.props.h1}</h1> }
                        { this.props.p && <p>{this.props.p}</p> }
                        { this.props.buttonLabel && <NavLink to={this.props.buttonTo} className="btn-primary">{this.props.buttonLabel}</NavLink>}
                    </div>
                    {this.props.large ? <img src={require('../../images/header.png')} alt="Speed Ministry" /> : null}
                </div>
            </header>
        );
    }
}

export default Header;
