import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import axios from 'axios';
import { Helmet } from 'react-helmet';

import Header from './components/Header.js';
import CTA from './components/CTA.js';

class Database extends React.Component {
    state = {
        show: 1,
        type: "car",
        currentProjects: [],
        manufacturers: [],
        manufacturerName: "",
        manufacturerSlug: "selected",
        models: [],
        modelName: "",
        modelSlug: "selected",
        powertrainId: "",
        powertrainName: null,
        powertrainSlug: "selected",
        powertrains: [],
        data: null,
        toggle: 0,
        length: 15,
        currentPage: 1,
        manufacturerTitle: "",
        modelTitle: "",
        powertrainTitle: ""
    }

    componentDidMount(){

        if(this.props.match.params.make){
            this.setState({
                manufacturerSlug: this.props.match.params.make
            },()=>{
                this.buildSearch(this.state.manufacturerSlug)
                this.populateModels(this.state.manufacturerSlug);
            })
        }

        if(this.props.match.params.model){
            this.setState({
                modelSlug: this.props.match.params.model
            },()=>{
                this.buildSearch(this.state.modelSlug);
                this.populatePowertrains(this.state.modelSlug);
            })
        }

        if(this.props.match.params.powertrain){
            this.setState({
                powertrainSlug: this.props.match.params.powertrain
            },()=>{
                this.buildSearch(this.state.powertrainSlug);
                this.appliedPowertrain(this.state.powertrainSlug);
            })
        }

        // Gets makes
        this.populateMakes();
    }

    populateMakes = () => {
        // Gets makes
        axios.get('https://api.speedministry.com/v1/projects/makes').then((res) => {

            console.log(res.data)

            this.setState({
                manufacturers: res.data,
                currentPage: 1
            },  () => {
                axios.get('https://api.speedministry.com/v1/projects?length=' + this.state.length + '&page=' + this.state.currentPage)
                .then((projectRes) => {
                    this.setState({
                        currentProjects: projectRes.data.projects,
                        prev: projectRes.data.pagination.prev,
                        currentPage: projectRes.data.pagination.page,
                        next: projectRes.data.pagination.next,
                        limit: projectRes.data.pagination.limit,
                        total: projectRes.data.pagination.total,
                        pages: projectRes.data.pagination.pages
                    })
                })
                .catch((err) => {
                    this.props.history.push('/error');
                })

                this.setState({
                    currentPage: 1
                }, () => {
                    this.buildSearch();
                })

            })
        })
    }

    populateModels = (slug) => {
        this.setState({
            manufacturerSlug: slug,
            modelSlug: "selected",
            powertrainSlug: "selected"
        },() => {
        // Gets models
            this.props.history.push('/projects/' + this.state.manufacturerSlug)

            axios.get('https://api.speedministry.com/v1/projects/makes/'+this.state.manufacturerSlug+'/models').then((res) => {
                this.setState({
                    models: res.data,
                    currentPage: 1,
                })
            }).catch((err) => {
                this.props.history.push('/projects');
            })

            this.setState({
                currentPage: 1
            }, () => {
                this.buildSearch();
            })
        })
    }

    populatePowertrains = (slug) => {
        this.setState({
            modelSlug: slug,
            powertrainSlug: "selected"
        }, () => {
        // Gets powertrains
            this.props.history.push('/projects/' + this.state.manufacturerSlug + '/' + this.state.modelSlug)
            axios.get('https://api.speedministry.com/v1/projects/makes/'+this.state.manufacturerSlug+'/models/' + this.state.modelSlug + '/powertrains').then((res) => {
                this.setState({
                    powertrains: res.data,
                    currentPage: 1,
                })
            }).catch((err) => {
                this.props.history.push('/projects');
            })

            this.setState({
                currentPage: 1
            }, () => {
                this.buildSearch();
            })
        })
    }
    appliedPowertrain = (slug) => {
        this.setState({
            powertrainSlug: slug
        }, () => {
            this.props.history.push('/projects/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + this.state.powertrainSlug)

            this.setState({
                currentPage: 1
            }, () => {
                this.buildSearch();
            })
        })
    }

    changePage(newPage){
        this.setState({
            currentPage: newPage
        }, () => {
            this.buildSearch();
        })
    }

    buildSearch = () => {
        let url = 'https://api.speedministry.com/v1/projects';

        if(this.state.manufacturerSlug != null && this.state.manufacturerSlug != "selected" && this.state.manufacturerSlug != 0){
            url = url + "/makes/" + this.state.manufacturerSlug;
        }

        if(this.state.modelSlug != null && this.state.modelSlug != "selected" && this.state.modelSlug != 0){
            url = url + "/models/" +  this.state.modelSlug;
        }

        if(this.state.powertrainSlug != null && this.state.powertrainSlug != "selected" && this.state.powertrainSlug != 0){
            url = url + "/powertrains/" +  this.state.powertrainSlug;
        }

        url = url + "?length=" + this.state.length + "&page=" + this.state.currentPage;
        axios.get(url).then((projectRes) => {
            if(projectRes.data.projects.length < 1){
                this.props.history.push('/projects')
                this.setState({
                    manufacturerSlug: "selected"
                })
            } else{
                this.setState({
                    currentProjects: projectRes.data.projects,
                    manufacturerTitle: projectRes.data.projects[0].make.name,
                    modelTitle: projectRes.data.projects[0].model.name,
                    powertrainTitle: projectRes.data.projects[0].powertrain.name,
                    prev: projectRes.data.pagination.prev,
                    next: projectRes.data.pagination.next,
                    limit: projectRes.data.pagination.limit,
                    total: projectRes.data.pagination.total,
                    pages: projectRes.data.pagination.pages
                })
            }
        }).catch((err) => {
            this.props.history.push('/projects')
            this.setState({
                manufacturerSlug: "selected"
            })
        })
    }


    render() {

        let vehicle = "Reseller";

        if(this.state.manufacturerSlug != null && this.state.manufacturerSlug != "selected" && this.state.manufacturerSlug != 0){
            vehicle = this.state.manufacturerTitle;
        }

        if(this.state.modelSlug != null && this.state.modelSlug != "selected" && this.state.modelSlug != 0){
            vehicle = vehicle + " " + this.state.modelTitle;
        }

        if(this.state.powertrainSlug != null && this.state.powertrainSlug != "selected" && this.state.powertrainSlug != 0){
            vehicle = vehicle + " " + this.state.powertrainTitle;
        }
        return (
            <>
                <Helmet>
                    <title>{`${vehicle} Project Database - Speed Ministry`}</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = {`${vehicle} Project Database`}
                />
                <section className="half nopad">
                    <div className="container highlight">
                        <form className="singleLine">
                            <select value={this.state.manufacturerSlug} onChange={(e) => {this.populateModels(e.target.value)}}>
                                <option disabled value="selected">Select Make</option>
                                {this.state.manufacturers.map((obj, idx) => {
                                    return (
                                        <option key={idx} value={obj.slug}>{obj.name}</option>
                                    )
                                })}
                            </select>

                            <select value={this.state.modelSlug} onChange={(e) => {this.populatePowertrains(e.target.value)}}>
                                {this.state.manufacturerSlug != null ? 
                                    <option disabled value="selected">Select Model</option>
                                    : null
                                }
                                {this.state.manufacturerSlug != null ? 
                                        this.state.models.map((obj, idx) => {
                                            if(idx = 0){
                                                return <option key={idx} selected value={obj.slug}>{obj.name + " " + obj.code}</option>
                                            } else {
                                                return <option key={idx} value={obj.slug}>{obj.name + " " + obj.code}</option>                                                
                                            }
                                    })
                                : 
                                    <option disabled selected>Select Model</option>
                                }
                            </select>  

                            
                            <select value={this.state.powertrainSlug} onChange={(e) => {this.appliedPowertrain(e.target.value)}}>
                                {this.state.modelSlug != null ? 
                                    <option disabled value="selected">Select Powertrain</option>
                                    : null
                                }
                                {this.state.modelSlug != null ? 
                                    this.state.powertrains.map((obj, idx) => {
                                        if(idx = 0){
                                            return <option key={idx} selected value={obj.slug}>{obj.name}</option>
                                        } else {
                                            return <option key={idx} value={obj.slug}>{obj.name}</option>
                                        }
                                    })
                                : 
                                    <option disabled selected>Select Powertrain</option>
                                }
                            </select>  
                        </form>
                    </div>
                </section>

                <section className="half nobot">
                    <div className="container database">
                        <div className="list">
                            <div className="item headings">
                                <div>
                                    <p>Project</p>
                                    <p>Vehicle</p>
                                    <p className="ecu">ECU Protocol</p>
                                    <p className="ecu">Production No</p>
                                </div>
                                <p>Credits</p>
                            </div>
                            
                            {this.state.currentProjects.map((obj, idx) => {

                                const make = obj.make.slug;
                                const model = obj.model.slug ? obj.model.slug : "x";
                                const powertrain = obj.powertrain.slug ? obj.powertrain.slug : "x";

                                return (
                                    <NavLink key={idx} to={`/projects/${make}/${model}/${powertrain}/${obj.id}`} className="item">
                                        <div>
                                            <p className="ecu">{obj.projectType}</p>
                                            <p>{obj.make.name + " " + obj.model.name + " " + obj.model.code + " " + obj.powertrain.name}</p>
                                            <p className="ecu">{obj.ecuProtocol.name}</p>
                                            <p>{obj.ecuProductionNo}</p>
                                        </div>
                                    
                                        <p>{obj.credits > 0 ? obj.credits: "0"} Credits</p>
                                    </NavLink>
                                )
                            })}
                        </div>
                        
                        <div className="pagination">
                            {this.state.prev != null ? 
                                <button className="btn-primary page" onClick={this.changePage.bind(this, this.state.prev)}>Prev</button>
                            : <button className="btn-primary page greyed">Prev</button>
                            }

                            <p>Page {this.state.currentPage} of {this.state.pages}</p>

                            {this.state.next != null ? 
                                <button className="btn-primary page" onClick={this.changePage.bind(this, this.state.next)}>Next</button>
                            : <button className="btn-primary page greyed">Next</button>
                            }
                        </div>
                    </div>
                </section>
                <CTA 
                    h3={`Add credits to your EVC account for as little as €66 to get started`}
                    p={`Access our database of over thousands of ECU tuning files directly from your EVC WinOLS software, from Original Files, to Stage 1's and Super Mappacks`}
                    buttonLabel = "Purchase EVC Credits"
                    buttonTo = "/credits"
                />
            </>
        );
    }
}

export default withRouter(Database);
