import * as React from 'react';

import { Helmet } from 'react-helmet';

import Header from './components/Header.js';

class Terms extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Something went wrong - Speed Ministry</title>
                    <meta name="description" content="" />
                </Helmet>
                <Header
                    large = {false}
                    h1 = "Something went wrong"
                    p = "It looks like something has gone wrong, we can't load the page you are looking for. Click the button below to go to our homepage. If the problem persits, contact our support team by emailing support@speedministry.com"
                    buttonLabel = "Go home"
                    buttonTo = "/"
                />
            </>
        );
    }
}

export default Terms;
