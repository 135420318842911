import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import axios from 'axios';
import HighChartsReact from "highcharts-react-official";
import HighCharts from "highcharts";
import cloneDeep from 'clone-deep';

import Header from './components/Header.js';
import CTA from './components/CTA.js';

class Dyno extends React.Component {

    state = {
        show: 1,
        searchInput: "",
        searchInputModel: "",
        type: "car",
        manufacturers: [],
        manufacturerName: "",
        manufacturerSlug: "",
        models: [],
        modelName: "",
        modelSlug: "",
        powertrainId: "",
        powertrainName: "",
        powertrains: [],
        data: null,
        toggle: 0
    };

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname != this.props.location.pathname){
            this.checkForParams();
        }
    }

    componentDidMount(){
        this.checkForParams();
    }

    checkForParams = () => {

        if(this.props.match.params.make){
            this.setState({
                manufacturerSlug: this.props.match.params.make
            },()=>{
                if(this.props.match.params.model){
                    this.setState({
                        modelSlug: this.props.match.params.model
                    },()=>{
                        if(this.props.match.params.powertrain){
                            this.setState({
                                powertrainId: this.props.match.params.powertrain
                            },()=>{
                                this.appliedPowertrain(this.state.powertrainId);
                            })
                        } else{
                            this.appliedModel(this.state.modelSlug);
                        }
                    })
                } else{
                    this.appliedMake(this.state.manufacturerSlug);
                }
            })
        } else{
            this.defaultRequest();
        }
    }

    defaultRequest = () => {
        // Gets makes
        axios.get('https://api.data.octalpowertrain.com/v1/dyno/vehicles/types/car/makes').then((res) => {
            this.setState({
                manufacturers: res.data,
                show: 1
            })
        }).catch((err) => {
            this.props.history.push('/error');
        })
    }

    appliedMake = (manufacturerSlug) => {
        this.setState({
            // Applies make details to state
            manufacturerSlug: manufacturerSlug
        }, () => {
            // this.props.history.push('/dyno-lookup/' + this.state.manufacturerSlug)
            // Gets models
            axios.get('https://api.data.octalpowertrain.com/v1/dyno/vehicles/types/car/makes/'+this.state.manufacturerSlug+'/models').then((res) => {
                this.setState({
                    show: 2,
                    models: res.data
                })
            }).catch((err) => {
                this.props.history.push('/dyno');
            })
        })
    }

    appliedModel = (modelSlug) => {
        // Applies model details to state
        this.setState({
            modelSlug: modelSlug
        }, () => {
            // this.props.history.push('/dyno-lookup/' + this.state.manufacturerSlug + '/' + this.state.modelSlug)
            // Gets powertrains
            axios.get('https://api.data.octalpowertrain.com/v1/dyno/vehicles/types/car/makes/'+this.state.manufacturerSlug+'/models/'+this.state.modelSlug+'/powertrains').then((res) => {    
            this.setState({
                    show: 3,
                    powertrains: res.data
                })
            }).catch((err) => {
                this.props.history.push('/dyno');
            })
        })
    }

    appliedPowertrain = (powertrainId) => {
        // Applies model details to state
        this.setState({
            powertrainId: powertrainId,
        }, () => {
            // this.props.history.push('/dyno-lookup/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + this.state.powertrainId)
            axios.get('https://api.data.octalpowertrain.com/v1/dyno/vehicles/id/' + this.state.powertrainId).then((res) => {
                console.log(res.data)
                let data = res.data;

                let stockHpMax = 0;
                data.dyno[0].stock.hp.forEach((val) => {
                    if(val > stockHpMax){
                        stockHpMax = val;
                    }
                })
                data.stockHpMax = stockHpMax

                let stockNmMax = 0;
                data.dyno[0].stock.nm.forEach((val) => {
                    if(val > stockNmMax){
                        stockNmMax = val;
                    }
                })
                data.stockNmMax = stockNmMax

                let stage1HpMax = 0;
                data.dyno[0].stage1.hp.forEach((val) => {
                    if(val > stage1HpMax){
                        stage1HpMax = val;
                    }
                })
                
                data.stage1HpMax = stage1HpMax

                let stage1NmMax = 0;
                data.dyno[0].stage1.nm.forEach((val) => {
                    if(val > stage1NmMax){
                        stage1NmMax = val;
                    }
                })
                data.stage1NmMax = stage1NmMax

                let stage2HpMax = 0;
                if(data.dyno[0].stage2 != null){
                    data.dyno[0].stage2.nm.forEach((val) => {
                        if(val > stage2HpMax){
                            stage2HpMax = val;
                        }
                    })
                } else{
                    stage2HpMax = 0
                }
                
                data.stage2HpMax = stage2HpMax

                let stage2NmMax = 0;
                if(data.dyno[0].stage2 != null){
                    data.dyno[0].stage2.nm.forEach((val) => {
                        if(val > stage2NmMax){
                            stage2NmMax = val;
                        }
                    })
                } else{
                    stage2NmMax = 0
                }
                data.stage2NmMax = stage2NmMax

                this.setState({
                    data: data,
                    show: 4
                })
            }).catch((err) => {
                this.props.history.push('/dyno');
            })
        })
    }

    render() {
        let ogURL = "https://speedministry.com";

        if(this.props.match.params.make){
            ogURL = ogURL + '/' + this.state.manufacturerSlug
        }
        if(this.props.match.params.model){
            ogURL = ogURL + '/' + this.state.modelSlug
        }
        if(this.props.match.params.powertrain){
            ogURL = ogURL + '/' + this.state.powertrainId
        }

        let makesArray = []
        this.state.manufacturers.map((obj) => {
            if(obj.name.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) > -1){
                makesArray.push(obj)
            }
        })

        let modelsArray = []
        this.state.models.map((obj) => {
            if(obj.name.toLowerCase().indexOf(this.state.searchInputModel.toLowerCase()) > -1){
                modelsArray.push(obj)
            }
        })

        let yStageHp = [];
        let yStageNm = [];
        let profileName = "";
        let vehicleName = "";
        let x = [];
        let yOemHp = [];
        let yOemNm = [];
        let frontProfileName = "";
        let maxY = 0;
        let maxYHp = 0;

        if(this.state.data != null){
            vehicleName = this.state.data.full_reference;
            x = this.state.data.dyno[0].rpm;
            yOemHp = this.state.data.dyno[0].stock.hp;
            yOemNm = this.state.data.dyno[0].stock.nm;
            if(this.state.toggle == 0){
                frontProfileName = "Stage 1";
                yStageHp = cloneDeep(this.state.data.dyno[0].stage1.hp);
                yStageNm = cloneDeep(this.state.data.dyno[0].stage1.nm);
            } else {
                frontProfileName = "Stage 1 +";
                yStageHp = cloneDeep(this.state.data.dyno[0].stage2.hp);
                yStageNm = cloneDeep(this.state.data.dyno[0].stage2.nm);
            }
    
            maxY = Math.max.apply(null, yOemNm.concat(yStageNm)) * 1.05;
            maxYHp = Math.max.apply(null, yOemHp.concat(yStageHp)) * 1.05;

            let zeros = [];

            x.forEach((thisX, i) => {
                if(yStageHp[i] === 0 && yOemNm[i] === 0) zeros.push(i)
            });

            x = x.filter((val, i) => { return !zeros.includes(i); });
            yOemHp = yOemHp.filter((val, i) => { return !zeros.includes(i); });
            yOemNm = yOemNm.filter((val, i) => { return !zeros.includes(i); });
            yStageHp = yStageHp.filter((val, i) => { return !zeros.includes(i); });
            yStageNm = yStageNm.filter((val, i) => { return !zeros.includes(i); });
        }

        let title = "Dyno Lookup";
        let sub = "Horse Power, Torque and Dynograph Data";

        if(this.state.data){
            title = `${this.state.data.make.name} ${this.state.data.model.name} ${this.state.data.powertrain}`;
            sub = "Horse Power, Torque and Dynograph Data";
        }

        return (

            <>
                <Helmet>
                    <title>{`${title} Dyno Data - Speed Ministry`}</title>
                    <meta name="description" content="The supplier of thousands of quality custom  ECU tuning file projects for production gasoline and diesel vehicles." />
                    {/* <meta property="og:url" content={ogURL}/> */}
                    <meta property="og:title" content={`${title} Dyno Data - Speed Ministry`} />

                </Helmet>
                <Header
                    large = {false}
                    h1 = {title}
                    p = {sub}
                />

                {this.state.show === 1 ?
                    <section className="nopad">
                        <div className="container">
                            <h2>Select a Make</h2>
                            <input className="highlight" type="text" placeholder="Manufacturer name..." value={this.state.searchInput} onChange={(e) => { this.setState({ searchInput: e.target.value }) }}/>
                            <div className="dynoList">
                                {makesArray.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/dyno-lookup/' + obj.slug); }}>
                                            { obj.logo.length > 0 ?
                                                <img src={"https://fra1.digitaloceanspaces.com/octal-content/vehicle-make-logos/" + obj.logo} alt=""/>
                                            : 
                                            <p>{obj.name}</p>
                                            }
                                        </div>
                                    )
                                })}
                            </div>  
                        </div>
                    </section>
                : null}

                {this.state.show === 2 ?
                    <section className="nopad">
                        <div className="container">
                            <h2>Select a Model</h2>
                            <input className="highlight" type="text" placeholder="Model name..." value={this.state.searchInputModel} onChange={(e) => { this.setState({ searchInputModel: e.target.value }) }}/>
                            <div className="dynoList">
                                {modelsArray.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/dyno-lookup/' + this.state.manufacturerSlug + '/' + obj.slug); }}>
                                            <p>{obj.name}</p>
                                        </div>
                                    )
                                })}
                            </div>  
                        </div>
                    </section>
                : null}
                
                {this.state.show === 3 ?
                    <section className="notop">
                        <div className="container database">
                            <h2>Select a Powertrain</h2>
                            <div className="dynoList">
                                {this.state.powertrains.map((obj, idx) => {
                                    return (
                                        <div key={idx} className="item" onClick={() => { this.props.history.push('/dyno-lookup/' + this.state.manufacturerSlug + '/' + this.state.modelSlug + '/' + obj.id); }}>
                                            <p>{obj.powertrain}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                : null}

                {this.state.show === 4 ?
                    <section className="nopad">
                        <div className="container database">
                            <div className="output">
                                <div className="table">
                                    <div className="dynoGrid">
                                        <p className="header">Profile</p>
                                        <p className="header">BHP</p>
                                        <p className="header">Torque</p>

                                        <p className="sidebar">Stock</p>
                                        <p className="main">{this.state.data.stockHpMax}</p>
                                        <p className="main">{this.state.data.stockNmMax}</p>

                                        <p className="sidebar" >Stage 1</p>
                                        <div className="main">
                                            <p>{this.state.data.stage1HpMax}</p>
                                            
                                        </div>
                                        <div className="main">
                                            <p>{this.state.data.stage1NmMax}</p>
                                        </div>

                                        <p className="sidebar">Stage 1 +</p>
                                        <div className="main">
                                            <p>{this.state.data.stage2HpMax}</p>
                                        </div>
                                        <div className="main">
                                            <p>{this.state.data.stage2NmMax}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="toggle">
                                    <button className={this.state.toggle == 0 ? 'active' : null} onClick={() => {
                                        this.setState({
                                            toggle: 0
                                        })
                                    }}>Stage 1</button>

                                    {this.state.data.stage2NmMax && this.state.data.stage2HpMax != null ?
                                        <button className={this.state.toggle == 1 ? 'active' : null} onClick={() => {
                                            this.setState({
                                                toggle: 1
                                            })
                                        }}>Stage 1 +</button>
                                    : 
                                        null
                                    }
                                </div>
                                <div className="graph">
                                    <HighChartsReact
                                        highcharts={HighCharts}
                                        options={{
                                            colors: ["rgba(255, 102, 0, 1)", "rgba(255, 102, 0, 0.50)", "rgba(68, 138, 204, 0.4)", "rgba(68, 138, 204, 0.2)", "#b7c5b8"],
                                            chart: {
                                                type: 'areaspline',
                                                backgroundColor: "none",
                                                borderWidth: 0,
                                                borderRadius: 0,
                                                plotBackgroundColor: null,
                                                plotShadow: false,
                                                plotBorderWidth: 0,
                                            },
                                            title: {
                                                text: vehicleName,
                                                style: {
                                                    color: '#fff',
                                                    font: '14px ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;',
                                                    fontWeight: 'bold',
                                                    align: 'center'
                                                }
                                            },
                                            exporting: {
                                                enableImages: true,
                                                scale: 10
                                            },
                                            subtitle: {
                                                text: '   ',
                                                style: {
                                                    color: '#DDD',
                                                    font: '12px ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;'
                                                }
                                            },
                                            xAxis: {
                                                title: {
                                                    text: 'RPM',
                                                    style: {
                                                        color: '#777777',
                                                        font: 'normal 11px ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;'
                                                    }
                                                },
                                                gridLineWidth: 1,
                                                gridLineColor: 'rgba(179, 179, 179, .5)',
                                                lineColor: '#555',
                                                tickColor: '#333',
                                                labels: {
                                                    style: {
                                                        color: '#555',
                                                        fontWeight: 'normal'
                                                    }
                                                },
                                                categories: x
                                            },
                        
                                            yAxis: [
                                                {
                                                    alternateGridColor: null,
                                                    minorTickInterval: null,
                                                    gridLineColor: 'rgba(179, 179, 179, .5)',
                                                    lineWidth: 0,
                                                    tickWidth: 0,
                                                    labels: {
                                                        formatter: function() {
                                                            return this.value;
                                                        },
                                                        style: {
                                                            color: '#ff6600'
                                                        }
                                                    },
                                                    min: 0,
                                                    max: maxYHp,
                                                    title: {
                                                        text: 'Power (HP)',
                                                        style: {
                                                            color: '#ff6600',
                                                            font: 'normal 11px ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;'
                                                        }
                                                    }
                                                },
                                                {
                                                    alternateGridColor: null,
                                                    minorTickInterval: null,
                                                    gridLineColor: 'rgba(255, 255, 255, .1)',
                                                    lineWidth: 0,
                                                    tickWidth: 0,
                                                    min: 0,
                                                    max: maxY,
                                                    opposite: true,
                                                    labels: {
                                                        formatter: function() {
                                                            return this.value;
                                                        },
                                                        style: {
                                                            color: '#448acc'
                                                        }
                                                    },
                                                    title: {
                                                        text: 'Torque (Nm)',
                                                        style: {
                                                            color: '#448acc',
                                                            font: 'normal 11px ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;'
                                                        }
                                                    }
                        
                                                }
                                            ],
                                            tooltip: {
                                                enabled: true,
                                                crosshairs: true,
                                                backgroundColor: '#3a3f41',
                                                borderWidth: 0,
                                                formatter: function() {
                                                    var unit = {
                                                        'Stock HP': 'HP',
                                                        'Stage 1 + HP': 'HP',
                                                        'Stage 1 HP': 'HP',
                                                        'Economy HP': 'HP',
                                                        'Stage HP': 'HP',
                                                        'Stock Torque': 'Nm',
                                                        'Stage 1 + Torque': 'Nm',
                                                        'Stage 1 Torque': 'Nm',
                                                        'Economy Torque': 'Nm',
                                                        'Stage Torque': 'Nm'
                                                    }[this.series.name];
                        
                                                    return '<b>'+ this.series.name +'</b><br/>'+ Math.round(this.y) + unit + ' @ '+ this.x +'RPM';
                                                },
                        
                                                style: {
                                                    color: '#FFF',
                                                    font: '9pt ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif'
                                                }
                                            },
                                            labels: {
                                                style: {
                                                    color: '#ccc',
                                                    fontWeight: 'normal'
                                                }
                                            },
                                            legend: {
                                                enabled: true,
                                                borderWidth:0,
                                                itemStyle: {
                                                    font: '9pt ProximaNova-Reg, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                                                    color: '#777777'
                                                },
                                                itemHoverStyle: {
                                                    color: '#df5b00'
                                                },
                                                itemHiddenStyle: {
                                                    color: '#CCC'
                                                }
                                            },
                                            plotOptions: {
                                                areaspline: {
                                                    fillOpacity: 0,
                                                    marker: {
                                                        enabled: false,
                                                        symbol: 'circle',
                                                        radius: 5,
                                                        lineWidth: 3,
                                                        states: {
                                                            hover: {
                                                                enabled: true
                                                            }
                                                        }
                                                    }
                                                },
                                                area: {
                                                    fillOpacity: 0,
                                                    marker: {
                                                        enabled: false,
                                                        symbol: 'circle',
                                                        radius: 5,
                                                        lineWidth: 3,
                                                        states: {
                                                            hover: {
                                                                enabled: true
                                                            }
                                                        }
                                                    }
                                                },
                                                spline: {
                                                    lineWidth: 2,
                                                    dataLabels: {
                                                        color: '#777777'
                                                    },
                                                    marker: {
                                                        enabled: false,
                                                        states: {
                                                            hover: {
                                                                enabled: true,
                                                                symbol: 'circle',
                                                                radius: 5,
                                                                lineWidth: 3
                                                            }
                                                        }
                                                    }
                                                },
                                                line: {
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    enableMouseTracking: true
                                                },
                                                series: {
                                                    lineWidth: 4
                                                }
                                            },
                        
                                            series: [{
                                                name: frontProfileName + ' HP',
                                                cursor: 'pointer',
                                                data: yStageHp
                                            },{
                                                name: 'Stock HP',
                                                cursor: 'pointer',
                                                data: yOemHp
                        
                                            },{
                                                name: frontProfileName +' Torque',
                                                cursor: 'pointer',
                                                yAxis: 1,
                                                data: yStageNm,
                                            },{
                                                name: 'Stock Torque',
                                                cursor: 'pointer',
                                                yAxis: 1,
                                                data: yOemNm
                                            }],
                                            navigation: {
                                                buttonOptions: {
                                                    enabled: false
                                                }
                                            },
                                            credits: {
                                                enabled: false
                                            },
                        
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                : null}
                <CTA 
                    h3={`Add credits to your EVC account for as little as €66 to get started`}
                    p={`Access our database of thousands of ECU tuning files directly from your EVC WinOLS software, from Original Files, to Stage 1's and Super Mappacks`}
                    buttonLabel = "Purchase EVC Credits"
                    buttonTo = "/credits"
                />
            </>
        );
    }
}

export default withRouter(Dyno);
