import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Offline } from "react-detect-offline";

class Nav extends React.Component {

    state = {
        open: false
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        if(this.state.open === true){
            if(window.innerWidth > 899){
                this.setState({
                    open: false
                })
            }
        }
    }

    toggleNav = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <>
                <Offline><div className="offline">We've detected you've gone offline. Please reconnect to the internet to continue.</div></Offline>

                <div className="nav">
                    <div className="container">
                        <NavLink to="/">
                            <img src={require("../../images/logo.svg")} alt="SMLogo.svg"/>
                        </NavLink>
                        <nav className={this.state.open ? "visible" : null} onClick={this.toggleNav.bind(this)} >
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/dyno-lookup">Dyno Lookup</NavLink>
                            <NavLink to="/protocol">Protocol Lookup</NavLink>
                            <NavLink to="/projects">Project Database</NavLink>
                            <NavLink to="/credits" className="btn-primary">Purchase EVC Credits</NavLink>
                        </nav>
                        <div id="hamburger" onClick={this.toggleNav} className={this.state.open ? "close" : null}><span></span></div>
                    </div>

                </div>
            </>
        );
    }
}

export default Nav;
