import React from 'react';
import { Switch, Route } from 'react-router-dom';
import '../css/style.css';

import axios from 'axios';
import ScrollToTop from './components/ScrollToTop.js';

import Home from './Home';
import Dyno from './Dyno';
import Database from './Database';
import Protocol from './Protocol';
import Project from './Project';
import Credits from './Credits';
import PaymentResult from './PaymentResult';
import Privacy from './Privacy';
import Terms from './Terms';
import Error from './Error';

import Nav from './components/Nav.js';
import Footer from './components/Footer.js';
import Messenger from './components/Messenger';

class App extends React.Component {

    state = {
        purchaseCredits: true,
        dyno: true,
        projects: true
    };

    componentDidMount(){
        // Check connection to EVC
        axios.get("https://api.speedministry.com/v1/uptime/evc")
        .catch(error => {
            this.setState({ purchaseCredits: false })
        });

        // Check connection to Octal Powertrain API
        axios.get("https://api.speedministry.com/v1/uptime")
        .then((res) => {
            if(res.data.success !== 1){
                this.setState({ dyno: false })
            }
        })
        .catch(error => {
            this.setState({ dyno: false })
        })

        // Check connection to Speed Ministry API
        axios.get("https://api.speedministry.com/v1/uptime")
        .then((res) => {
            if(res.data.success !== 1){
                this.setState({ projects: false })
            }
        })
        .catch(error => {
            this.setState({ projects: false })
        })
    }

    render() {
        return (
            <>
                <Messenger />
                <Nav />
                <Switch>    
                    <Route exact path="/" component={Home} />
                    <Route exact path="/dyno-lookup" component={this.state.dyno ? Dyno : Error} />
                    <Route exact path="/dyno-lookup/:make" component={this.state.dyno ? Dyno : Error} />
                    <Route exact path="/dyno-lookup/:make/:model" component={this.state.dyno ? Dyno : Error} />
                    <Route exact path="/dyno-lookup/:make/:model/:powertrain" component={this.state.dyno ? Dyno : Error} />
                    <Route exact path="/protocol" component={this.state.dyno ? Protocol : Error} />
                    <Route exact path="/protocol/:make" component={this.state.dyno ? Protocol : Error} />
                    <Route exact path="/protocol/:make/:model" component={this.state.dyno ? Protocol : Error} />
                    <Route exact path="/protocol/:make/:model/:year" component={this.state.dyno ? Protocol : Error} />
                    <Route exact path="/protocol/:make/:model/:year/:powertrain" component={this.state.dyno ? Protocol : Error} />
                    <Route exact path="/projects" component={this.state.projects ? Database : Error} />
                    <Route exact path="/projects/:make" component={this.state.projects ? Database : Error} />
                    <Route exact path="/projects/:make/:model" component={this.state.projects ? Database : Error} />
                    <Route exact path="/projects/:make/:model/:powertrain" component={this.state.projects ? Database : Error} />
                    <Route exact path="/projects/:make/:model/:powertrain/:project" component={this.state.projects ? Project : Error} />
                    <Route exact path="/credits" component={this.state.purchaseCredits ? Credits : Error} />
                    <Route exact path="/credits/:result" component={this.state.purchaseCredits ? PaymentResult : Error} />
                    <Route exact path="/privacy-policy" component={Privacy} />
                    <Route exact path="/terms-and-conditions" component={Terms} />
                    <Route path="" component={Error} />
                </Switch>
                <Footer />
                <ScrollToTop />
            </>
        );
    }
};

export default App;