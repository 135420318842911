import * as React from 'react';
import { Helmet } from 'react-helmet';

import qs from 'qs';
import ReactGA from 'react-ga';

import Header from './components/Header.js';

class PaymentResult extends React.Component {

    state = {
        status: "",
    }

    componentDidMount(){
        if(this.props.match.params.result === "success"){
            this.setState({
                status: "Completed"
            });

            const credits = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).credits;

            let value = 0;
            if(credits === 10) value = 80;
            if(credits === 50) value = 350;
            if(credits === 100) value = 600;
            if(credits === 500) value =  2500;

            ReactGA.initialize("UA-147194496-2");
            ReactGA.event({
                category: 'transaction',
                action: 'purchase',
                value
            });
        } else if(this.props.match.params.result === "cancel"){
            this.setState({
                status: "Abandoned"
            })
        } else {
            window.location.href = "/";
        }
    }

    render() {

        let message = "";
        let button = {}

        if(this.state.status == "Completed"){
            message = "Your "+qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).credits+" credits have now been added to your EVC account ("+qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).account+").";
            button = {
                label: "Go Home",
                to: "/"
            }
        } else {
            message = "There has been an issue with your transaction, you have not been charged.";
            button = {
                label: "Try Again",
                to: "/credits"
            }
        }
        
        return(
            this.state.status != null ?
                <>
                    <Helmet>
                        <title>Transaction {this.state.status} - Speed Ministry</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <Header
                        large = {false}
                        h1 = {"Your Transaction was "+this.state.status}
                        p = {message}
                        buttonLabel = {button.label}
                        buttonTo = {button.to}
                    />
                </>
            : 
                <p>Loading...</p>
        )
    }
}

export default PaymentResult;
