import React from 'react';

class Messenger extends React.Component {

    componentDidMount() {
        window.fbAsyncInit = function() {
            FB.init({
                xfbml            : true,
                version          : 'v9.0'
            });
        };

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    render() {
        return (
            <>
                {/*<div id="fb-root" />*/}
                {/*<div className="fb-customerchat"*/}
                {/*     attribution="setup_tool"*/}
                {/*     page_id="325336221418435"*/}
                {/*     theme_color="#ff7e29">*/}
                {/*</div>*/}
            </>
        );
    }
}

export default Messenger;
