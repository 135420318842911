import React from 'react';
import { NavLink } from 'react-router-dom';

class CTA extends React.Component {
    render() {
        return (
            <>
                <section className="nobot">
                    <div className="container highlight">
                        <div className="split banner">
                            <div>
                                { this.props.h3 && <h3>{this.props.h3}</h3> }
                                { this.props.p && <p>{this.props.p}</p> }
                            </div>
                            { this.props.buttonLabel && <NavLink to={this.props.buttonTo} className="btn-primary">{this.props.buttonLabel}</NavLink>}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default CTA;
