import React from 'react';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import axios from 'axios';

import Header from './components/Header.js';
import CTA from './components/CTA.js';
import Nav from "./components/Nav";

class Home extends React.Component {

    state = {
        total: 0
    };

    componentDidMount(){
        axios.get("https://api.speedministry.com/v1/projects/count")
        .then((res) => {
            this.setState({
                total: res.data.total
            })
        })
    }

    render() {

        let count = this.state.total.toString();
        count = count.substring(0, count.length - 1) + "0";
        count = count.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return (
            <>
                <Helmet>
                    <title>Speed Ministry™ - Official Reseller Database for WinOLS ECU Tuning File Projects</title>
                    <meta property="og:url" content="https://speedministry.com" />
                    <meta property="og:title" content="Speed Ministry™ - Official Reseller Database for WinOLS ECU Tuning File Projects" />
                </Helmet>

                <Header
                    large = {true}
                    h1 = {`Over ${count}+ WinOLS Reseller Database Projects`}
                    p = {`Speed Ministry supplies quality custom  ECU tuning file projects for production gasoline and diesel vehicles.`}
                />

                <div className="container makes">
                    <NavLink to={`/projects/audi`}>
                        <img src={require("../images/makes/audi.png")} alt="Audi Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/bmw`}>
                        <img src={require("../images/makes/bmw.png")} alt="BMW Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/hyundai`}>
                        <img src={require("../images/makes/hyundai.png")} alt="Hyundai Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/jeep`}>
                        <img src={require("../images/makes/jeep.png")} alt="Jeep Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/mini`}>
                        <img src={require("../images/makes/mini.png")} alt="Mini Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/mitsubishi`}>
                        <img src={require("../images/makes/mitsubishi.png")} alt="Mitsubishi Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/seat`}>
                        <img src={require("../images/makes/seat.png")} alt="Seat Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/skoda`}>
                        <img src={require("../images/makes/skoda.png")} alt="Skoda Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/toyota`}>
                        <img src={require("../images/makes/toyota.png")} alt="Toyota Tuning Files" />
                    </NavLink>
                    <NavLink to={`/projects/vw`}>
                        <img src={require("../images/makes/vw.png")} alt="VW Tuning Files" />
                    </NavLink>
                </div>
                
                <section className="half nopad">
                    <div className="container">
                        <div className="colContainer">
                            <div className="column">
                                <div className="icon">
                                    <img src={require("../images/icons/one.svg")} alt="Tokens.svg" />
                                    <h3>Stage File</h3>
                                    <p>Single 'Stage 1' Base File</p>
                                    <NavLink to="/credits" className="btn-primary">8-12 EVC Credits</NavLink>
                                </div>
                            </div>

                            <div className="column">
                                <span>Most Popular</span>
                                <div className="icon">
                                    <img src={require("../images/icons/three.svg")} alt="Tokens.svg" />
                                    <h3>Stage File Package</h3>
                                    <p>Tuning project with up to 5 file versions</p>
                                    <NavLink to="/credits" className="btn-primary">15-25 EVC Credits</NavLink>
                                </div>
                            </div>

                            <div className="column">
                                <div className="icon">
                                    <img src={require("../images/icons/five.svg")} alt="Tokens.svg" />
                                    <h3>Super Mappack</h3>
                                    <p>Full A2L project upto 120,000 maps defined</p>
                                    <NavLink to="/credits" className="btn-primary">25-60 EVC Credits</NavLink>
                                </div>
                            </div>
                        </div>  
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="about">
                            <div>
                                <h2>About Speed Ministry</h2>
                                <p>'SpeedMinistry OLSx' the dedicated EVC reseller platform - Supported by our continuous product development using our dedicated R+D dyno departments. With over 50 years combined tuning experience supplying tuning files to more than 15,000 clients in 150 countries via our sister platforms. Our wholesale pricing will ensure you keep competitive in today's retail market.</p>
                                <p>Our EVC reseller platform with thousands of projects updated daily varying from stage1 base to a fully defined Hex - A2L project all ready for instant download via your EVC reseller account 24/7 365 days a year.</p>
                                <p>We develop using Bapro, dynocom and MAHA products with some unique advantages over our competitors due to our HQ location, staff credentials and access to a private runway. Since 1997 we have worked in vehicle calibration understanding the current demands of chiptuning and its continued dilution to the end user masses, SpeedMinistry OLSx is placed as the professional B2B platform of our 3 products for quick quality tuning file support for experienced WinOLS users.</p>
                                <div className="download">
                                    <a target="_blank" href="https://apps.apple.com/ca/app/speed-ministry/id1482755444">
                                        <img src={require("../images/icons/apple-large.svg")} alt="AppStoreLogo.svg" />                                    
                                    </a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.speedministryapp">
                                        <img src={require("../images/icons/google-large.svg")} alt="GooglePlayLogo.svg" />
                                    </a>
                                </div>
                            </div>
                            <div>
                                <img src={require("../images/mockup.png")} alt="Mobile App" />
                            </div>
                        </div>
                        <div className="usps">
                            <div>
                                <img src={require("../images/icons/database.svg")} alt="Database" />
                                <p>Thousands of A2L / asap2 full descriptions, supermap packs, and SPW plugin generated map packs available online for immediate download.</p>
                            </div>
                            <div>
                                <img src={require("../images/icons/pops.svg")} alt="Pops and Bangs" />
                                <p>Tested tuning solutions  / Lambda monitoring / EGR - DPF programming / Hardcut limiters / overrun crackles / switchable maps / 91-98 RON solutions plus many more.</p>
                            </div>
                            <div>
                                <img src={require("../images/icons/mobile.svg")} alt="Mobile Apps" />
                                <p>iOS and Android mobile apps, allowing you to purchase EVC credits, lookup vehicle details and browser our reseller project database.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="grey">
                    <div className="container">
                        <div>
                            <h2>Tool Compatability</h2>
                            <p>All master tools supported.</p>
                            <div className="tools">
                                <a href="https://www.alientech-tools.com/en/" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/alientech.png")} alt="Alientech Logo" />
                                </a>
                                <a href="https://www.autotuner-tool.com/en" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/autotuner.png")} alt="Autotuner Logo" />
                                </a>
                                <a href="https://www.bflash.eu/" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/bflash.png")} alt="B Flash Logo" />
                                </a>
                                <a href="https://www.flashtec.ch/" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/cmd.png")} alt="CMD Logo" />
                                </a>
                                <a href="https://www.dimsport.it/en/" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/dimsport.png")} alt="Dimsport Logo" />
                                </a>
                                <a href="https://www.magicmotorsport.com/" target="_blank" rel="noopener">
                                    <img src={require("../images/tools/magic.png")} alt="Magic Motorsport Logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <CTA 
                    h3={`Add credits to your EVC account for as little as €66 to get started`}
                    p={`Access our database of over ${count} ECU tuning files directly from your EVC WinOLS software, from Original Files, to Stage 1's and Super Mappacks`}
                    buttonLabel = "Purchase EVC Credits"
                    buttonTo = "/credits"
                />
            </>
        );
    }
}

export default Home;
